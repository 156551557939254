import logo from './logo.svg';
import './App.css';
import Resume from './Components/Resume';

function App() {
  return (
   <>
    <Resume />
   </>
  );
}

export default App;
